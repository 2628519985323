var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"create-user-modal","centered":"","size":"lg","no-close-on-esc":true,"no-close-on-backdrop":true,"dialog-class":"my-dialog-class"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("Create User")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"createUserFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"username"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Username "),_c('span',{staticClass:"text-danger"},[_vm._v("* ")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Username","rules":{ required: _vm.required, regex: /^[A-Z0-9]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"placeholder":"Username"},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'username', 100)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Password","rules":{
                required: true,
                regex:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"fullName"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Full Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Full Name","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"restrict",rawName:"v-restrict",value:(_vm.regex),expression:"regex"}],attrs:{"id":"fullName","state":errors.length > 0 ? false : null,"placeholder":"Full Name"},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'full_name', 200)}},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"mobile"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mobile "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Mobile","rules":"required|integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Mobile"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Email"},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'email', 100)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('VueSelectPaginated',{attrs:{"placeholder":"Role","name":"Role","label":"name","rules":"required","searchBy":"name","getListMethod":_vm.getRoles},on:{"setMethod":function (value) {
                _vm.role = value;
              }}})],1)],1),_c('p',{staticClass:"font-weight-bolder text-danger"},[_vm._v(" NOTE: "),_c('span',{staticClass:"font-weight-bold text-dark"},[_vm._v("The password must be at least 8 characters long, at least one lowercase letter, at least one uppercase letter, at least one digit and at least one special character (e.g., @$!%*?&)")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }